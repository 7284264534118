import React, { Fragment, useState, useEffect, useContext } from 'react'
import _get from 'lodash/get'
import { graphql } from 'gatsby'
import Image from '../components/Image'

import Content from '../components/Content'
import Layout from '../components/Layout'

import './SinglePost.css'
// import TalkyardCommentsIframe from '@debiki/gatsby-plugin-talkyard'
import MyContext from '../components/Context'

import { CgZoomIn, CgZoomOut, CgPrinter } from 'react-icons/cg'

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share'

export const SinglePostTemplate = ({
  title,
  date,
  body,

  categories,

  featuredImage,
  lang
}) => {
  const [fontS, setFontS] = useState(22)

  const { strings, i18n } = useContext(MyContext)

  const [postURL, setPostURL] = useState('')

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang)
    }

    setPostURL(window.location.href)
  }, [])

  const changeFontSize = type => {
    if (type == 'in') {
      if (fontS < 25) {
        setFontS(fontS + 1)
      }
    } else if (type == 'out') {
      if (fontS > 17) {
        setFontS(fontS - 1)
      }
    }
  }
  return (
    <MyContext.Consumer>
      {cons => {
        if (!cons) {
          var cons = {
            strings: e => e,
            i18n: {
              language: 'ar'
            }
          }
        }

        return (
          <div style={{ direction: lang == 'en' ? 'ltr' : 'rtl' }}>
            <main>
              <article
                className="SinglePost  light"
                itemScope
                itemType="http://schema.org/BlogPosting"
              >
                <div
                  className="SinglePost--Content relative"
                  style={{ padding: '15px' }}
                >
                  <div className="cc" style={{}}>
                    <div
                      style={{
                        display: 'flex',
                        marginBottom: '50px',
                        justifyContent: 'space-between',

                        alignItems: 'center',
                        flexWrap: 'wrap'
                      }}
                    >
                      <div style={{ display: 'flex', marginBottom: '15px' }}>
                        <CgZoomIn
                          onClick={() => changeFontSize('in')}
                          style={{
                            fontSize: '25px',
                            margin: '0px 5px',
                            cursor: 'pointer',
                            // color: '#a3c89c'
                            color: 'gray'
                          }}
                        />
                        <CgZoomOut
                          onClick={() => changeFontSize('out')}
                          style={{
                            fontSize: '25px',
                            margin: '0px 5px',
                            cursor: 'pointer',
                            // color: '#a3c89c'
                            color: 'gray'
                          }}
                        />
                        <CgPrinter
                          onClick={() => {
                            window.print()
                            return false
                          }}
                          style={{
                            fontSize: '25px',
                            margin: '0px 5px',
                            cursor: 'pointer',
                            // color: '#77a4d4'
                            color: 'gray'
                          }}
                        />
                      </div>
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            // width: '30%'
                            flex: 1,
                            justifyContent: 'flex-end'
                          }}
                        >
                          <p
                            style={{
                              fontWeight: '900',
                              marginBottom: '0px',
                              textAlign: 'center',
                              marginRight: '6px',
                              fontSize: '18px',
                              color: 'gray'
                            }}
                          >
                            {cons.strings('shareArticle')}
                          </p>
                          <EmailShareButton
                            style={{ marginRight: '6px' }}
                            url={postURL && postURL}
                            subject={`${title} | حركة سورية الأم`}
                          >
                            <EmailIcon size={32} round />
                          </EmailShareButton>

                          <FacebookShareButton
                            style={{ marginRight: '6px' }}
                            url={postURL && postURL}
                            quote={`${title} | حركة سورية الأم`}
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>

                          <TwitterShareButton
                            style={{ marginRight: '6px' }}
                            url={postURL && postURL}
                            title={`${title} | حركة سورية الأم`}
                          >
                            <TwitterIcon size={32} round />
                          </TwitterShareButton>

                          <WhatsappShareButton
                            style={{ marginRight: '6px' }}
                            url={postURL && postURL}
                            title={`${title} | حركة سورية الأم`}
                          >
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>

                          <TelegramShareButton
                            style={{ marginRight: '6px' }}
                            url={postURL && postURL}
                            title={`${title} | حركة سورية الأم`}
                          >
                            <TelegramIcon size={32} round />
                          </TelegramShareButton>
                        </div>
                      </div>
                    </div>

                    {categories == 'announcements' && (
                      <div
                        style={{ textAlign: 'center', marginBottom: '10px' }}
                      >
                        <img
                          style={{
                            height: `200px`
                          }}
                          src={'/images/ee.png'}
                          alt="Logo"
                        />
                      </div>
                    )}
                    <div
                      className="SinglePost--Meta"
                      style={{ fontSize: '15px' }}
                    >
                      {date && (
                        <time
                          className="SinglePost--Meta--Date"
                          itemProp="dateCreated pubdate datePublished"
                          date={date}
                        >
                          {date}
                        </time>
                      )}
                      {categories && (
                        <Fragment>
                          <span>{` | `}</span>
                          <span className="SinglePost--Meta--Category">
                            {cons.strings(categories)}
                          </span>
                        </Fragment>
                      )}
                    </div>
                  </div>

                  <div style={{}}>
                    <div
                      className="vv"
                      style={{ textAlign: 'center', marginBottom: '50px' }}
                    >
                      <img
                        style={{
                          height: `200px`
                        }}
                        src={'/images/ee.png'}
                        alt="Logo"
                      />
                    </div>
                    {title && (
                      <h1
                        className="SinglePost--Title"
                        style={{ fontFamily: 'lotus' }}
                        itemProp="title"
                      >
                        {title}
                      </h1>
                    )}
                    <div
                      className="SinglePost--InnerContent default-printing-font-size"
                      style={{
                        textAlign: 'start',
                        fontSize: `${fontS}px`,
                        fontFamily: 'lotus',
                        wordBreak: 'break-word'
                      }}
                    >
                      {featuredImage && (
                        <div
                          className="relative PageHeader"
                          style={{
                            margin: '0 0 2rem 0'
                            // width: '100%',
                            // height: '20rem'
                          }}
                        >
                          <Image
                            background
                            src={featuredImage}
                            size="contain"
                            alt={title}
                          />
                        </div>
                      )}
                      <Content source={body} />
                    </div>
                  </div>
                  {/* <div className="cc">
                    <div>
                      <TalkyardCommentsIframe />
                    </div>
                  </div> */}

                  {/* <div
                        className="SinglePost--Pagination"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between'
                        }}
                      >
                        {prevPostURL && (
                          <Link
                            style={{ textDecoration: 'none' }}
                            //                     className="SinglePost--Pagination--Link prev"
                            to={prevPostURL}
                          >
                            {strings('Previous Post')}
                          </Link>
                        )}
                        {nextPostURL && (
                          <Link
                            style={{ textDecoration: 'none' }}
                            //                     className="SinglePost--Pagination--Link next"
                            to={nextPostURL}
                          >
                            {strings('Next Post')}
                          </Link>
                        )}
                      </div> */}
                </div>
              </article>
            </main>
          </div>
        )
      }}
    </MyContext.Consumer>
  )
}

// Export Default SinglePost for front-end
const SinglePost = ({ data: { post, allPosts } }) => {
  const thisEdge = allPosts.edges.find(edge => edge.node.id === post.id)
  return (
    <Layout
      meta={post.frontmatter.meta || false}
      spTitle={post.frontmatter.title || false}
      metaFeaturedImage={post.frontmatter.featuredImage || ''}
      postLang={post.frontmatter.lang}
      isSinglePost={true}
    >
      <SinglePostTemplate
        {...post}
        {...post.frontmatter}
        body={post.html}
        nextPostURL={_get(thisEdge, 'next.fields.slug')}
        prevPostURL={_get(thisEdge, 'previous.fields.slug')}
      />
    </Layout>
  )
}

export default SinglePost

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SinglePost($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        template
        subtitle
        date(formatString: "MMMM Do, YYYY")
        categories
        featuredImage
        lang
      }
    }

    allPosts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
        }
        next {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        previous {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
